import React from 'react';

const texts = {
  dynamicWords: ["AI", "transition", "for", "a", "Bright", "Future"],
  leftBox: {
    title: "Accelerate Your Digital Momentum with Our Comprehensive Tech-Solutions Suite",
    content: [
      "In a rapidly evolving digital landscape, staying ahead of the curve requires a harmonious blend of innovative strategy, cutting-edge technology, and insightful leadership. Our suite of professional services is designed to catalyze your business transition into the future, ensuring you harness the full potential of emerging technologies to achieve unprecedented growth and sustainability.",
      "Leverage our holistic service portfolio to drive a successful digital transformation. Embark on a journey of innovation, operational excellence, and strategic growth. Your ambition, coupled with our tech-savvy expertise, paves the way for a promising digital frontier."
    ]
  },
  aboutBox: {
    title: "About Bright Future AI",
    content: [
      "BFAI is a strategic consulting, and R&D firm. Our advisory experience helps our international and domestic partners to understand, evaluate, and penetrate in the global market. We also act as a growth partner for your business.",
      "With our experienced team, we have worked with a wide range of emerging technologies including ML/AI and AR/VR, also with varieties of innovation levels from academic research toward feature delivery, and all within a wide range of industries."
    ]
  },
  centerContents: [
    {
      title: "AI Transformation and Transition Services",
      content: "Transition into an AI-enriched operational frontier with our AI Transformation and Transition Services, meticulously crafted in alliance with PHI Institute, redmarble.ai, and Go AI. Our tailored approach is aimed at fostering a robust AI ecosystem within your enterprise, ensuring a seamless integration while upholding the highest standards of compliance and ethical conduct."
    },
    {
      title: "AI Project, Solution, and R&D Services",
      content: "From startup ventures to multinational cooperations, we aid in realizing your visionary projects through our AI expertise. Covering a broad spectrum of technologies including Data Science, Transformer Solutions, Deep Learning, RNN, CNN, LSTM, and GPT solutions, we help automate and augment your operations. Whether it’s client service automation or complex AI-driven projects, we provide the innovative muscle to meet your objectives."
    },
    {
      title: "R&D and Emerging Technologies Delivery",
      content: "Our R&D services extend beyond AI into emerging realms like Augmented Reality (AR), Virtual Reality (VR), Extended Reality (XR), and Blockchain. With our expertise in medical imaging and logistics, we propel your projects from conception to reality, ensuring robust solution delivery that stands the test of time."
    },
    {
      title: "Agile Transformation and Consultancy Services",
      content: "Embrace agility to navigate through organizational challenges effortlessly. Our Agile transition and coaching services, encompassing project delivery and crisis management, are tailored to foster a culture of continuous improvement. Through pragmatic process enhancements, we help you build a resilient organization ready to thrive amidst change."
    }
  ],
  videos: [
    {
      type: "text",
      // header: "",
      description: [
        {
          title: "",
          desc: "At Bright Future AI Services, we are committed to bridging today’s enterprise operations with tomorrow’s AI-driven innovation. Our collaborative venture with PHI Institute, redmarble.ai, and Go AI unfolds a spectrum of AI Transformation and Transition Services. Through these partnerships, we’re orchestrating a seamless transition for businesses poised at the cusp of AI integration, ensuring a harmonized infusion of AI into the operational milieu.",
          list: [
            "Strategic AI Roadmapping: Together with PHI Institute, we devise a strategic AI roadmap that aligns with your organizational ethos and goals, easing the transition towards AI-centric operations.",
            "Customized AI Solutions: Drawing upon the expertise of redmarble.ai and Go AI, we architect and implement tailored AI solutions, driving a substantial uplift in operational efficiency and innovation.",
            "Continuous Skill Enhancement: Beyond implementation, we provide exhaustive training initiatives, empowering your teams to excel in an AI-augmented operational arena.",
            "Ethical AI Implementation: We uphold the tenets of ethical AI, ensuring a balanced approach towards privacy, data security, and bias mitigation across all AI engagements."],
          paragraph: "Bright Future AI Services is your trusted ally in navigating the complex terrain of AI transformation, ensuring a smooth transition braced with strategic insight, ethical diligence and a future-forward vision."
        },
        {
          title: "Service Highlights:",
          desc: "",
          list: [
            "PHI Institute Collaboration: Expertise in Cognitive Computing and Machine Learning Models, Ethical AI Advocacy for Secure and Responsible Data Management, Bespoke AI Strategy and Roadmap Development",
            "redmarble.ai Alliance: Leading-edge Predictive Analysis and AI-powered Automation Solutions, Interactive AI Solution Prototyping, Continuous AI Improvement and Scalability Framework.",
            "Go AI Partnership: Tailored AI Consultancy for Responsible AI Integration into Enterprise Strategy and Policies, Ensuring Compliance with Existing Regulations, ISO Certifications, and Pragmatic Risk Management, Focused Governance to address Bias, Privacy, and Data Security in AI-driven Operations.",
          ],
          paragraph: " "
        }
      ]
    },
    // {
    //   type: "text",
    //   header: "At Bright Future AI Services, we are committed to bridging today’s enterprise operations with tomorrow’s AI-driven innovation. Our collaborative venture with PHI Institute, redmarble.ai, and Go AI unfolds a spectrum of AI Transformation and Transition Services. Through these partnerships, we’re orchestrating a seamless transition for businesses poised at the cusp of AI integration, ensuring a harmonized infusion of AI into the operational milieu.",
    //   paragraphs: [
    //     "At Bright Future AI Services, we are committed to bridging today’s enterprise operations with tomorrow’s AI-driven innovation. Our collaborative venture with PHI Institute, redmarble.ai, and Go AI unfolds a spectrum of AI Transformation and Transition Services. Through these partnerships, we’re orchestrating a seamless transition for businesses poised at the cusp of AI integration, ensuring a harmonized infusion of AI into the operational milieu.",
    //     "Bright Future AI Services is your trusted ally in navigating the complex terrain of AI transformation, ensuring a smooth transition braced with strategic insight, ethical diligence and a future-forward vision.",
    //   ],
    //   list: [
    //     "Strategic AI Roadmapping: Together with PHI Institute, we devise a strategic AI roadmap that aligns with your organizational ethos and goals, easing the transition towards AI-centric operations.",
    //     "Customized AI Solutions: Drawing upon the expertise of redmarble.ai and Go AI, we architect and implement tailored AI solutions, driving a substantial uplift in operational efficiency and innovation.",
    //     "Continuous Skill Enhancement: Beyond implementation, we provide exhaustive training initiatives, empowering your teams to excel in an AI-augmented operational arena.",
    //     "Ethical AI Implementation: We uphold the tenets of ethical AI, ensuring a balanced approach towards privacy, data security, and bias mitigation across all AI engagements.",
    //   ],
    // },
    {
      type: "video-text",
      header: "AI Project, Solution, and R&D Services",
      videoPaths: [
        "871740673",
        "871740818",
        "871740745",
        "871740758",
        "871740714",
        "871740731",
      ],
      description: [
        "Augmented Reality - Real time, ToF sensor, Unity, CUDA",
        "Augmented Reality - Real time, ToF sensor, Unity, CUDA",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML-AI segm, fusion of CT/MRI, VR anatomy navigation"
      ]
    },
    {
      type: "video-text",
      header: "R&D and Emerging Technologies Delivery",
      videoPaths: [
        "871738046",
        "871739393",
        "871740673",
        "871740818",
        "871740745",
        "871740758",
        "871740714",
        "871740731",
        "871740699",
        "871740779"
      ],
      description: [
        "AR / mobile / Unity / indoor navigation",
        "VR lab, Unity, Lidar, drone, SLAM, spatial reconstruction",
        "Augmented Reality - Real time, ToF sensor, Unity, CUDA",
        "Augmented Reality - Real time, ToF sensor, Unity, CUDA",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML-AI segm, fusion of CT/MRI, VR anatomy navigation",
        "AI segmentation & classification",
        "WebGL, Unity, CAD, SaaS, Web, Stripe"
      ]
    },
    {
      type: "video-text",
      header: "Agile Transformation and Consultancy Services",
      videoPaths: [
        "871740699",
        "871740779",
        "871740673",
        "871740818",
        "871740745",
        "871740758",
        "871740714",
        "871740731"
      ],
      description: [
        "AI segmentation & classification",
        "WebGL, Unity, CAD, SaaS, Web, Stripe",
        "Augmented Reality - Real time, ToF sensor, Unity, CUDA",
        "Augmented Reality - Real time, ToF sensor, Unity, CUDA",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML / AI / multi level / real time - opt / turn acc research into product",
        "ML-AI segm, fusion of CT/MRI, VR anatomy navigation"
      ]
    }
  ]
};

export default texts;
