import React, { useState, useEffect, useRef } from 'react';
import '../styles/About.css';
import texts from './texts';

function About() {

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.6;
    }
  }, []);

  return (
    <div className="video-background-about">
      <video ref={videoRef} autoPlay loop muted>
        <source src="/Vid24k.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <div className="overlay-logo">
        <img src="/bfailogofeher.png" alt="Company Logo" />
      </div> */}
      <div className="translucent-box-about">
        <h2>{texts.aboutBox.title}</h2>
        <p>{texts.aboutBox.content[0]}</p>
        <p>{texts.aboutBox.content[1]}</p>
      </div>
    </div>
  );
}

export default About;
