import React from 'react';
import '../styles/Logo.css';

function Logo() {
  return (
    <img src="/bfailogofeher.svg" alt="Company Logo" className="logo" />
  );
}

export default Logo;
