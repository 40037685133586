import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/navbar.css';
import Logo from './Logo';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar ${scrolled ? "navbar-scrolled" : ""}`}>
      <Logo />
      <div className="navbar-left">
        {/* <img src="/bfailogofeher.png" alt="Company Logo" className="navbar-logo" /> */}
        <span>AI services</span>
        <p>info@bf-ai.com</p>
      </div>
      <div className="navbar-right">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/contacts">Contact us</Link>
      </div>
    </div>
  );
}

export default Navbar;
