import React, { useState, useEffect, useRef } from 'react';
import '../styles/Home.css';
import texts from './texts';
import RefContent from './RefContent';

function Home() {
  const [dynamicText, setDynamicText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const words = texts.dynamicWords;
  const videoRef = useRef(null);
  const [centerContentIndex, setCenterContentIndex] = useState(0);
  const [leftBoxIndex, setLeftBoxIndex] = useState(0);
  const [contentAnimationDone, setContentAnimationDone] = useState(false);
  const [selectedVideoContentIndex, setSelectedVideoContentIndex] = useState(null);
  const changeIntervalRef = useRef(null);
  const centerContentIndexRef = useRef(centerContentIndex);
  const leftBoxIndexRef = useRef(leftBoxIndex);
  const refContentRef = useRef(null);

  useEffect(() => {
    centerContentIndexRef.current = centerContentIndex;
  }, [centerContentIndex]);

  useEffect(() => {
    leftBoxIndexRef.current = leftBoxIndex;
  }, [leftBoxIndex]);

  function restartInterval() {
    clearInterval(changeIntervalRef.current);
    changeIntervalRef.current = setInterval(() => {
      const nextCenterIndex = (centerContentIndexRef.current + 1) % texts.centerContents.length;
      setCenterContentIndex(nextCenterIndex);
      playFlipAnimationAndChangeContent(nextCenterIndex);

      const nextLeftBoxIndex = (leftBoxIndexRef.current + 1) % texts.leftBox.content.length;
      setLeftBoxIndex(nextLeftBoxIndex);
    }, 8000);
  }

  function handleTriggerButtonClick(index) {
    if (index !== centerContentIndex) {
      if (selectedVideoContentIndex !== null) {
        setSelectedVideoContentIndex(null);
        setTimeout(() => {
          playFlipAnimationAndChangeContent(index);
        }, 50);
      } else {
        playFlipAnimationAndChangeContent(index);
      }

      restartInterval();
    }
  }

  function playFlipAnimationAndChangeContent(newIndex) {
    const contentElement = document.querySelector(".center-content");
    contentElement.style.animation = "flipContent 1s forwards";

    setTimeout(() => {
      setCenterContentIndex(newIndex);
      contentElement.style.animation = "";
    }, 500);
  }


  function handleMoreButtonClick(index) {
    setSelectedVideoContentIndex(prevIndex => (prevIndex === index ? null : index));
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.7;
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentAnimationDone(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (currentIndex < words.length) {
      const timer = setTimeout(() => {
        if (currentIndex === -1) {
          setDynamicText(words[0]);
          setCurrentIndex(1);
        } else if (currentIndex < words.length - 1) {
          setDynamicText(prev => `${prev} ${words[currentIndex]}`);
          setCurrentIndex(prev => prev + 1);
        } else {
          setDynamicText(words.join(' '));
        }
      }, 5);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, words]);

  useEffect(() => {
    restartInterval();
    return () => clearInterval(changeIntervalRef.current);
  }, []);

  useEffect(() => {
    if (selectedVideoContentIndex !== null) {
      clearInterval(changeIntervalRef.current);
    } else {
      restartInterval();
    }
  }, [selectedVideoContentIndex]);



  return (
    <div>
      <div className="video-background">
        <video ref={videoRef} autoPlay loop muted>
          <source src="/Vid24k.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <div className="overlay-logo">
          <img src="/bfailogofeher.png" alt="Company Logo" />
        </div> */}
        <div className="overlay-text">
          {dynamicText}
        </div>
        <div className="translucent-box-container"
          onClick={() => {
            setLeftBoxIndex(prev => (prev + 1) % texts.leftBox.content.length);
            restartInterval();
          }}>
          <div className="translucent-box">
            <h2>{texts.leftBox.title}</h2>
            <p>{texts.leftBox.content[leftBoxIndex]}</p>
          </div>
        </div>
        <div className={`content-container ${contentAnimationDone ? "animate" : ""}`}>
          <div className="trigger-buttons">
            {texts.centerContents.map((content, index) => (
              <div key={index} className={`trigger-button ${index === centerContentIndex ? 'active' : ''}`} onClick={() => handleTriggerButtonClick(index)}>
                {content.title}
              </div>
            ))}
          </div>
          <div className="center-content">
            <p>{texts.centerContents[centerContentIndex].content}</p>
            <button
              className="more-button"
              onClick={() => handleMoreButtonClick(centerContentIndex)}
            >
              References
            </button>
          </div>
        </div>
      </div>
      {selectedVideoContentIndex !== null &&
        <RefContent ref={refContentRef} index={selectedVideoContentIndex} />}
    </div>
  );
}

export default React.memo(Home);
