import React, { useState, useEffect, useRef } from 'react';
import '../styles/Contacts.css';

function Contacts() {

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.6;
    }
  }, []);

  return (
    <div className="video-background-contact">
      <video ref={videoRef} autoPlay loop muted>
        <source src="/Vid24k.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <div className="overlay-logo">
        <img src="/bfailogofeher.png" alt="Company Logo" />
      </div> */}
      <div className="contact-container">
        <h2>Contact Us</h2>
        <div className="contact-info">
          <div>
            <h2>Bright Future AI Services</h2>
          </div>
          <div>
            <h2>Email</h2>
            <p><a href="mailto:info@bf-ai.com">info@bf-ai.com</a></p>
          </div>
          <div>
            <h2>Phone</h2>
            <p><a href="tel:+36209999129">+36209999129</a></p>
          </div>
          <div>
            <h2>Address</h2>
            <p>Petőfi utca 16. 2636 Tésa Hungary</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
