import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-left">
        <img src="/bfailogofeher.svg" alt="Company Logo" className="footer-logo" />
        <p>BrightFutureAI© 2023 - All rights reserved</p>
      </div>

      <div className="footer-center">
        <h2>Menu</h2>
        <div className="footer-links">
          <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/contacts">Contact us</a>
        </div>
      </div>

      <div className="footer-right">
        <h2>Follow us!</h2>
        <a href="https://www.linkedin.com/company/bright-future-ai-services/" target="_blank" rel="noreferrer">
          <img src="/linkedin.png" alt="LinkedIn" className="social-icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
