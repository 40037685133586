import React, { forwardRef, useEffect, useState, useRef } from 'react';
import texts from './texts';

function RefContent({ index }, ref) {
  const currentReference = texts.videos[index];
  const videoRefs = useRef([]);

  const hasVideos = currentReference.videoPaths && currentReference.videoPaths.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    setIsContentLoaded(true);
  }, []);


  useEffect(() => {
    if (currentReference.type === "video-text") {
      const activeVideo = document.querySelector(".ref-video.active");
      if (activeVideo && activeVideo.paused) {
        const playPromise = activeVideo.play();

        if (playPromise !== undefined) {
          playPromise
            .then(_ => { })
            .catch(error => {
              console.error("Video autoplay failed:", error);
            });
        }
      }
    }
  }, [currentIndex, currentReference.type]);

  useEffect(() => {
    if (!isContentLoaded) return;

    const scrollTarget = document.querySelector(".video-content");
    if (scrollTarget) {
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log("Element is invalid.");
    }
  }, [index, isContentLoaded]);



  function handleNext() {
    const len = currentReference.type === 'video-text' ? currentReference.videoPaths.length : currentReference.description.length;
    setCurrentIndex((prev) => (prev + 1) % len);
  }

  function handlePrevious() {
    const len = currentReference.type === 'video-text' ? currentReference.videoPaths.length : currentReference.description.length;
    setCurrentIndex((prev) => (prev - 1 + len) % len);
  }

  return (
    <div className="video-content">
      <div className='ref-content'>
        {currentReference.type === "video-text" && hasVideos ? (
          <>
            <div className="ref-video-container">
              {currentReference.videoPaths.map((path, idx) => (
                idx === currentIndex ? (
                  <div className="responsive-iframe">
                    <iframe
                      key={path}
                      src={`https://player.vimeo.com/video/${path}?autoplay=1&muted=1&loop=1`}
                      width="1440"
                      height="720"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      className="ref-video active">
                    </iframe>
                  </div>
                ) : null
              ))}
              <div className="ref-navigation">
                <button onClick={handlePrevious} className="ref-nav-button">←</button>
                <button onClick={handleNext} className="ref-nav-button">→</button>
              </div>
            </div>
            <p>{currentReference.description[currentIndex]}</p>
            <h2>{currentReference.header}</h2>
          </>
        ) : null}

        {currentReference.type === "text" ? (
          <>
            <div className="ref-navigation-text">
              <button onClick={handlePrevious} className="ref-nav-button">←</button>
              <button onClick={handleNext} className="ref-nav-button">→</button>
            </div>
            <div className="ref-paragraph">
              <span>{currentReference.description[currentIndex].paragraph}</span>
              <ul>
                {currentReference.description[currentIndex].list.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
              <span>{currentReference.description[currentIndex].desc}</span>
            </div>
            <h3>{currentReference.description[currentIndex].title}</h3>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default React.memo(forwardRef(RefContent));
